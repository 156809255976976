@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Roboto:wght@400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Ensure the html and body take full height and remove default margins */
html, body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    color: #333;
    min-height: 100vh;
    overflow-x: hidden;
}

/* Set box-sizing for all elements */
*, *::before, *::after {
  box-sizing: inherit; /* Inherit box-sizing from html */
}

/* Home component styles */
.home-container {
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    color: white;
}

.home-background-image {
  width: 100%;
  height: 100vh;
  background: url("../../public/images/gg3.jpg") no-repeat center center/cover;
  filter: brightness(50%);
}

.home-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.home-content h1 {
  font-size: 3em;
  margin: 0;
}

.home-content h2 {
  font-size: 6.5em;
  font-family: "Great Vibes", cursive;
  color: #d4af37;
  margin: 0;
}

.home-carousel {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-carousel-item {
  margin: 0 15px;
  text-align: center;
}

.home-carousel-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid white;
}

.home-carousel-item p {
  margin: 5px 0 0;
  font-size: 0.9em;
}

.home-carousel-item span {
  font-size: 0.8em;
  color: #ccc;
}

.home-nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  color: white;
  cursor: pointer;
}

.home-nav-arrow.left {
  left: 20px;
}

.home-nav-arrow.right {
  right: 20px;
}

/* Other styles remain unchanged */
/* Other styles remain unchanged */

/* Other styles remain unchanged */
 .about-section {
   background-color: #fafafa;
   padding: 50px 0;
   color: #333;
 }
 .about-container {
   display: flex;
   align-items: center;
   justify-content: center;
   max-width: 1200px;
   margin: 0 auto;
 }
 .image-section {
   display: flex;
   gap: 20px;
 }
 .image-section img {
   width: 350px;
   height: 500px;
   object-fit: cover;
 }
 .text-section {
   padding: 20px;
   background: #fff;
   border: 1px solid #d1a18f;
   border-radius: 10px;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
   margin-left: -50px;
   z-index: 1;
   max-width: 400px;
   height: 200px;
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 .text-section h2 {
   font-size: 24px;
   color: #333;
   margin-bottom: 10px;
 }
 .text-section h3 {
   font-family: "Great Vibes", cursive;
   font-size: 36px;
   color: #b45f06;
   margin-bottom: 20px;
 }
 .text-section p {
   font-size: 16px;
   color: #666;
   line-height: 1.6;
 }

 /* New styles for menu page */
 .menu-container {
   width: 90%;
   margin: 0 auto;
   text-align: center;
 }
 .menu-header {
   margin: 50px 0;
 }
 .menu-header h1 {
   font-family: "Great Vibes", cursive;
   font-size: 48px;
   color: #b89b72;
   margin: 0;
 }
 .menu-header h2 {
   font-family: "Roboto", sans-serif;
   font-size: 36px;
   color: #333;
   margin: 0;
 }
 .menu-section {
   display: flex;
   justify-content: space-between;
   margin: 50px 0;
 }
 .menu-section h3 {
   font-size: 24px;
   color: #333;
   font-weight: 700;
   margin-bottom: 20px;
 }
 .menu-items {
   width: 32%;
 }
 .menu-item {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: 1px solid #eee;
   border-radius: 8px;
   padding: 20px;
   margin-bottom: 20px;
   transition: background-color 0.3s, border-color 0.3s;
 }
 .menu-item:hover {
   background-color: #ffe6f2;
   border-color: #ff66b2;
 }
 .menu-item:focus,
 .menu-item.selected {
   background-color: #ff66b2;
   border-color: #cc0052;
 }
 .menu-item img {
   border-radius: 50%;
   width: 80px;
   height: 80px;
   object-fit: cover;
 }
 .menu-item .details {
   text-align: left;
   margin-left: 20px;
   flex-grow: 1;
 }
 .menu-item .details h4 {
   font-size: 18px;
   color: #333;
   margin: 0;
 }
 .menu-item .details p {
   font-size: 14px;
   color: #666;
   margin: 5px 0 0;
 }
 .menu-item .price {
   font-size: 18px;
   color: #333;
   font-weight: 700;
 }
 .menu-item .price span {
   color: #b89b72;
 }
 nav {
   background-color: #333;
   padding: 10px 0;
 }
 nav ul {
   list-style: none;
   padding: 0;
   margin: 0;
   display: flex;
   justify-content: center;
 }
 nav ul li {
   margin: 0 15px;
 }
 nav ul li a {
   color: #fff;
   text-decoration: none;
   font-size: 18px;
   transition: color 0.3s;
 }
 nav ul li a:hover,
 nav ul li a:focus {
   color: #ff66b2;
 }
 @media (max-width: 768px) {
   .carousel-item {
     display: none;
   }
   .about-container {
     flex-direction: column;
   }
   .image-section {
     flex-direction: column;
   }
   .text-section {
     margin-left: 0;
     margin-top: -50px;
   }
   .menu-section {
     flex-direction: column;
   }
   .menu-items {
     width: 100%;
   }
 }
 .header {
   text-align: center;
   padding: 50px 0;
 }
 .header h1 {
   font-size: 2.5em;
   margin: 0;
 }
 .header h1 span {
   font-family: "Brush Script MT", cursive;
   font-size: 1.5em;
   color: #d4a373;
 }
 .gallery {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   padding: 0;
 }
 .gallery img {
   width: 25%;
   height: 300px;
   object-fit: cover;
 }


 .master-chef-wrapper {
   font-family: "Arial", sans-serif;
   margin: 0;
   padding: 0;
   background-color: #fafafa;
   text-align: center;
 }
 .master-chef-container {
   width: 80%;
   margin: 0 auto;
 }

 .master-chef-title h1 {
   font-size: 2.5em;
   color: #333;
 }
 .master-chef-title h1 span {
   font-family: "Brush Script MT", cursive;
   font-size: 1.5em;
   color: #c59d5f;
 }
 .master-chef-team {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   margin-top: 50px;
 }
 .master-chef-team-member {
   width: 22%;
   margin-bottom: 30px;
 }
 .master-chef-team-member img {
   width: 100%;
   border-radius: 10px;
 }
 .master-chef-team-member h3 {
   font-size: 1.2em;
   color: #333;
   margin: 10px 0 5px 0;
 }
 .master-chef-team-member p {
   font-size: 0.9em;
   color: #777;
   margin: 0 0 10px 0;
 }
 .master-chef-social-icons {
   font-size: 1em;
   color: #c59d5f;
 }
 .master-chef-social-icons a {
   color: #c59d5f;
   margin: 0 5px;
   text-decoration: none;
 }
 @media (max-width: 768px) {
   .master-chef-team-member {
     width: 45%;
   }
 }
 @media (max-width: 480px) {
   .master-chef-team-member {
     width: 45%;
   }
 }

 
 .cake-order-page {
    margin: 0;
    padding: 20px;
    padding-left: 90px;
    font-family: "Roboto", sans-serif;
    background: url("../../public/images/da11.jpg") no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
  }
  
  .cake-order-page .container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  
  .cake-order-page .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cake-order-page .header h1 {
    font-family: "Great Vibes", cursive;
    font-size: 36px;
    margin: 0;
    color: #d4af37;
  }
  
  .cake-order-page .header h2 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    margin: 0;
    color: #333;
  }
  
  .cake-order-page .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .cake-order-page .form-group > div {
    width: calc(50% - 10px);
  }
  
  .cake-order-page .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 14px;
  }
  
  .cake-order-page .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .cake-order-page .btn {
    display: block;
    width: 100%;
    padding: 15px;
    background: #d4af37;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .cake-order-page .btn:hover {
    background: #b38e2c;
  }
  
  @media (max-width: 600px) {
    .cake-order-page {
      padding: 10px;
      justify-content: center;
    }
    
    .cake-order-page .form-group > div {
      width: 100%;
    }
    
    .cake-order-page .form-group input {
      margin-bottom: 10px;
    }
  }

  .menu-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
    gap: 20px; /* Spacing between items */
    padding: 20px;
  }
  
  /* Ensure items are neatly spaced and visually appealing */
  .menu-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer; /* Makes the entire card clickable */
  }
  
  .menu-item:hover {
    transform: scale(1.05); /* Zoom effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  }
  
  .details h4 {
    margin: 10px 0;
    font-size: 18px;
  }
  
  .price {
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Responsive Design for Tablets */
  @media (max-width: 768px) {
    .menu-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
      gap: 15px;
    }
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 480px) {
    .menu-grid {
      grid-template-columns: 1fr; /* 1 column on mobile */
      gap: 10px;
    }
  }
  