/* Import Great Vibes font */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #000000;
}

/* Navbar styles with reduced height */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color:  #000000 !important;
  position: relative;
}

/* Header text styling */
.navbar h1,
.navbar h2,
.navbar h3,
.navbar h4,
.navbar-brand,
.nav-link,
.navbar .logo-text {
  font-family: "Great Vibes", cursive !important;
  color: #D4AF37 !important;
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-size: 1.5rem;  /* Reduced from 2rem */
}

.logo {
  max-width: 150px;   /* Reduced from 300px */
  height: 100px;      /* Reduced from 200px */
}

.navbar-toggler {
  display: none;
  border: none;
  background: none;
  font-size: 1.25rem;  /* Reduced from 1.5rem */
  cursor: pointer;
  color: #D4AF37 !important;
}

.navbar-collapse {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.navbar-center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.navbar-nav {
  display: flex;
  align-items: center;
  gap: 0.75rem;  /* Reduced from 1.5rem */
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-link {
  text-decoration: none;
  font-size: 1.25rem !important;  /* Reduced from 1.5rem */
  padding: 0.25rem;  /* Reduced from 0.5rem */
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #B4943E !important;
}

/* Right side (search, cart, user menu) */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;  /* Reduced from 1rem */
}

.search-container {
  max-width: 300px;  /* Reduced from 400px */
}

.cart-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #D4AF37 !important;
}

#cart {
  margin-right: 0.25rem;  /* Reduced from 0.5rem */
  color: #D4AF37 !important;
}

#cart_count {
  margin-left: 4px;  /* Reduced from 8px */
  font-size: 12px;   /* Reduced from 14px */
  background-color: red;
  color: white;
  padding: 1px 4px;  /* Reduced from 2px 6px */
  border-radius: 50%;
}

/* User dropdown menu */
.user-menu {
  position: relative;
  margin-left: 1rem;  /* Reduced from 2rem */
}

.user-menu .dropdown-toggle {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #D4AF37 !important;
  font-family: "Great Vibes", cursive !important;
}

.avatar-nav {
  width: 20px;       /* Reduced from 30px */
  height: 20px;      /* Reduced from 30px */
  margin-right: 0.25rem;  /* Reduced from 0.5rem */
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #D4AF37;
  padding: 0.25rem;  /* Reduced from 0.5rem */
  min-width: 150px;  /* Reduced from 200px */
  z-index: 1000;
}

.dropdown-menu a {
  font-family: "Great Vibes", cursive !important;
  color: #D4AF37 !important;
  font-size: 1rem;  /* Reduced from 1.2rem */
}

#login_btn {
  margin-left: 1rem;  /* Reduced from 2rem */
  padding: 0.25rem 0.5rem;  /* Reduced from 0.5rem 1rem */
  border: 1px solid #D4AF37;
  background: #fff;
  cursor: pointer;
  font-family: "Great Vibes", cursive !important;
  color: #D4AF37 !important;
  font-size: 1.1rem;  /* Reduced from 1.3rem */
}

#login_btn:hover {
  background: #f8f8f8;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .navbar-collapse {
    display: none;
    flex-direction: column;
    width: 100%;
  }
  
  .navbar-collapse.show {
    display: flex;
  }
  
  .navbar-toggler {
    display: block;
  }
  
  .navbar-center {
    display: none;
  }
  
  .navbar-right {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .search-container {
    margin-bottom: 0.5rem;  /* Reduced from 1rem */
  }
  
  .cart-link {
    margin-bottom: 0.5rem;  /* Reduced from 1rem */
  }

  .navbar-brand {
    font-size: 1.25rem;  /* Additional reduction for mobile */
  }

  .nav-link {
    font-size: 1rem !important;  /* Additional reduction for mobile */
  }
}

/* Cart icon styles */
.cart-icon {
  display: none;
  color: #D4AF37 !important;
}

/* Show cart icon only on phone screens */
@media (max-width: 768px) {
  .cart-icon {
    display: inline-block;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23D4AF37' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}