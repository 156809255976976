html,
body {
  font-family: "Cookie", cursive;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background:#f5f5f5;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0;
  background-clip: padding-box;
  border-radius: 5px;
}

nav {
  padding: 1rem 1rem;
}

.navbar {
  padding: 0.1rem 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

#search_btn {
  background-color: #D4AF37;
}

.input-group {
  width: 90%;
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 1rem;
}

#login_btn,
#view_btn,
#new_review_btn {
  background-color: #D9B4C1;
  padding: 0.4rem 1.8rem;
  color: white;
}

#cart {
  font-size: 1rem;
  color: white;
}

#cart_count {
  background-color: #D9B4C1;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Home page */
#products_heading {
  margin-top: 1.8rem;
}
/* /////////////////////////////////////////////////////////////////////////////// */
.card {
  width: 250px; /* Card width */
  height: 350px; /* Card height */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0px; /* Adjusts spacing between cards */
  padding: 0; /* Removes any internal padding */
  background-color: #ffffff;
}

.card-title a {
  color: #2e2e2e;
  text-decoration: none;
}

.card-title a:hover {
  color: #D9B4C1;
  text-decoration: none;
}

.card-body {
  padding: 10px; /* Optional: adds some padding inside the card */
  flex: 1;
}
.h5, h5 {
  font-size: 1rem !important;
}
.card-text {
  font-size: 1.4rem;
}

.card-img-top {
  width: 100%;
  height: 150px;
  object-fit: contain; /* Ensures the image covers the area */
}

.ratings {
  font-size: 1.2rem;
  color: #D9B4C1;
}

#no_of_reviews {
  font-size: 0.9rem;
  color: grey;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}



/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-weight: bold;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #b7336a;
  border: none;
  padding: 0.5rem 2rem;
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red !important;
}

.greenColor {
  color: green !important;
}

#product_image img {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Ensures the image keeps its aspect ratio */
}

/* Product row using grid */
#productsRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items per row by default (small screens) */
  gap: 15px; /* Spacing between items */
  grid-auto-rows: 1fr; 
}

/* For larger screens */
@media (min-width: 768px) {
  #productsRow {
    grid-template-columns: repeat(3, 1fr); /* Three items per row on medium to large screens */
  }
}

/* For even larger screens */
@media (min-width: 1200px) {
  #productsRow {
    grid-template-columns: repeat(6, 1fr); /* Four items per row on large screens */
  }
}



/* /////////////////////////////////////////////////////////////////////////////// */
/* Loader */
.loader {
  display: block;
  margin: 20% auto;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #D9B4C1;
  border-color: #D9B4C1 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_price {
  color: #D9B4C1;
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #D9B4C1;
  border-color: #D9B4C1;
  margin-top: 2rem;
  border-radius: 5rem;
}

#view_order_details {
  background-color: #D9B4C1;
  border-color: #D9B4C1;
}

#order_summary {
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 3rem;
  text-align: center;
}

.plus,
.minus {
  padding: 0.1rem 0.5rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  
}

/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-weight: 700;
}

.wrapper form {
  padding: 2.5rem 3rem;
  
}

.wrapper form .btn,
.fetch-btn {
  background-color: #D9B4C1;
  border-color: #D9B4C1;
  color: white;
  margin-top: 1rem;
}

.fetch-btn:hover {
  background-color: #D9B4C1;
  color: white;
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
  
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #D9B4C1;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #D9B4C1;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #D9B4C1;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #D9B4C1;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

.triangle-complete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #d9d9d9;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-complete {
  width: 0;
  float: left;
  border-top: 20px solid #d9d9d9;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #d9d9d9;
  margin-right: -1px;
}

/* Adjustments for small screens */
@media (max-width: 768px) {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card {
    width: 90%;
    height: auto;
  }
  
  .input-group {
    width: 100%;
    margin: 0;
  }
  
  .navbar {
    padding: 0.5rem;
  }
  
  .wrapper {
    margin-top: 2rem;
  }
}




.filter {
  border: 1px solid black;
  width: 199px;
  box-sizing: border-box; 
}

/* Full viewport height and centering */
.not-found-container {
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.page-not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-image {
  max-width: 100%;
  height: auto;
}

/* Optional for responsive design */
@media (max-width: 768px) {
  .not-found-image {
      width: 100%; /* Make the image responsive */
      height: auto;
  }
}

#product_price {
  background-color: #f0f0f0; /* or any other background color */
  border: 1px solid #ddd; /* or any other border styling */
  padding: 10px 20px; /* adjust as needed */
  margin: 5px; /* add some spacing between buttons */
  cursor: pointer;
  border-radius: 5px; /* rounded corners */
  font-size: 16px; /* adjust as needed */
  text-align: center;
  display: inline-block;
  
}

#product_price:hover {
  background-color: #e0e0e0; /* change color on hover */
}

/* Only buttons inside the .custom-section will be styled */
.custom-section .btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Primary and secondary styles within the specific section */
.custom-section .btn-primary {
  background-color: #740947;
  color: white;
  border: 2px solid #740947;
}

.custom-section .btn-primary:hover {
  background-color: #740947;
  border-color: #740947;
}

.custom-section .btn-secondary {
  background-color: #e0e0e0;
  color: #333;
  border: 2px solid #e0e0e0;
}

.custom-section .btn-secondary:hover {
  background-color: #d5d5d5;
  border-color: #d5d5d5;
}
/* Scoped Button Styles */
.flavour-selection .btn,
.size-selection .btn,
.base-selection .btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  text-align: center;
  display: inline-block;
}

/* Button Hover State */
.flavour-selection .btn:hover,
.size-selection .btn:hover,
.base-selection .btn:hover {
  background-color: #e0e0e0;
}

/* Button Selected State */
.flavour-selection .btn-selected,
.size-selection .btn-selected,
.base-selection .btn-selected {
  background-color: #4CAF50; /* Selected button background */
  color: white;
}

/* Disabled Button State */
.flavour-selection .btn:disabled,
.size-selection .btn:disabled,
.base-selection .btn:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Stack buttons vertically on small screens */
  .flavour-selection .btn,
  .size-selection .btn,
  .base-selection .btn {
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem; /* Increase font size for better readability on small screens */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small screens */
  .flavour-selection .btn,
  .size-selection .btn,
  .base-selection .btn {
    padding: 12px 10px; /* Increase padding for touch targets */
    font-size: 1.1rem; /* Larger font size for better readability */
  }
}

.selection-btn {
  /* Add your custom styles here */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  flex: 1 1 30%; /* added flexbox styles */
  margin: 5px; /* added margin for spacing */
}

.selection-btn.btn-primary {
  /* Add your custom styles for the selected button */
  background-color: #b7336a;
  color: #ffffff;
  border-color: #b7336a;
}

.selection-btn.btn-outline-primary {
  /* Add your custom styles for the unselected button */
  color: #740947;
  border-color: #740947;
}

/* Media queries for mobile screens */
@media only screen and (max-width: 768px) {
  .selection-btn {
    padding: 8px 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .selection-btn {
    padding: 6px 10px;
    font-size: 12px;
  }
  .btn-group { /* added styles for button group */
    flex-wrap: wrap; /* allow buttons to wrap to next line */
    justify-content: center; /* center the buttons */
  }
  .selection-btn {
    flex: 1 1 45%; /* adjust flex basis for smaller screens */
  }
}

/* */
/* Base container styles */
.breadcrumb-container {
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
  z-index: 10; /* Increased z-index */
  overflow: visible; /* Changed from hidden to visible */
  display: block !important; /* Force display */
}

.breadcrumb-nav {
  background-color: #fce7f3;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Added position relative */
  display: block !important; /* Force display */
}

/* Title styles */
.breadcrumb-title {
  background-color: #ec4899;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 12px;
  text-align: center;
  position: relative; /* Added position relative */
}

.breadcrumb-title h5 {
  color: white;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

/* Steps container */
.breadcrumb-steps {
  display: flex !important; /* Force display flex */
  gap: 8px;
  position: relative;
  width: 100%;
  flex-wrap: nowrap; /* Prevent wrapping */
}

/* Individual step items */
.step-item {
  flex: 1;
  min-width: auto;
  white-space: nowrap;
  display: block !important; /* Force display */
}

.step-button {
  background: none;
  border: none;
  color: #9d174d;
  padding: 6px 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: center;
}

.step-button:not(:disabled):hover {
  background-color: #fbcfe8;
}

.step-button.current {
  background-color: #fbcfe8;
  cursor: default;
}

.step-arrow {
  color: #ec4899;
  font-size: 12px;
  display: inline-block; /* Ensure visibility */
}

.current-step {
  color: #ec4899;
  font-weight: 600;
}

/* Large screens */
@media (min-width: 1201px) {
  .breadcrumb-container {
    margin-bottom: 30px;
  }
}

/* Medium screens */
@media (max-width: 1200px) {
  .breadcrumb-container {
    display: block !important;
    position: static; /* Changed to static positioning */
    margin: 15px 0;
  }

  .breadcrumb-steps {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 8px;
  }
}

/* Tablet screens */
@media (max-width: 991px) {
  .breadcrumb-container {
    margin: 12px 0;
  }

  .breadcrumb-title h5 {
    font-size: 15px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .breadcrumb-container {
    margin: 10px 0;
    display: block !important;
  }

  .breadcrumb-nav {
    padding: 8px;
  }

  .breadcrumb-title h5 {
    font-size: 14px;
  }

  .breadcrumb-steps {
    display: flex !important;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    gap: 4px;
    padding-bottom: 8px;
  }

  .step-item {
    flex: 0 0 auto;
    scroll-snap-align: start;
    width: auto;
    min-width: max-content;
  }

  .step-button {
    font-size: 12px;
    padding: 4px 6px;
    white-space: nowrap;
  }

  /* Hide scrollbar but keep functionality */
  .breadcrumb-steps::-webkit-scrollbar {
    display: none;
  }
  
  .breadcrumb-steps {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* Extra small devices */
@media (max-width: 375px) {
  .breadcrumb-title h5 {
    font-size: 13px;
  }

  .step-button {
    font-size: 11px;
    padding: 3px 5px;
  }
}

/* Selection area styles - Ensure they don't overlap */
.size-selection,
.flavour-selection,
.base-selection,
.form-group {
  position: relative;
  z-index: 1; /* Lower z-index than breadcrumb */
  margin-top: 20px;
  clear: both;
}

/* */
/* Modal overlay */
.promo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.promo-modal {
  background: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Close button */
.promo-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

/* Title */
.promo-title {
  font-size: 24px;
  color: #FF69B4;
  margin-bottom: 10px;
}

/* Message */
.promo-message {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

/* Terms */
.promo-terms {
  font-size: 12px;
  color: #555;
  margin-bottom: 20px;
}

/* Order button */
.promo-order-btn {
  background: #FFD700;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.promo-order-btn:hover {
  transform: scale(1.1);
}

/* Sprinkles */
.promo-sprinkles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.sprinkle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: fall 2s linear infinite;
}

@keyframes fall {
  from {
    top: -10%;
    opacity: 1;
  }
  to {
    top: 110%;
    opacity: 0;
  }
}


