#container {
  width: 100vw; /* Full viewport width */
  height: 100vh;
  position: relative;
  background: linear-gradient(to bottom,#F2C4DA,#F2E6C2,#F2DCC2,#F2BBBB,#F2D5D5) !important;
  background-repeat: no-repeat;
  height: 100%;
}

/* uparrow */
.up-arrow {
  position: fixed;
  right: 3%;
  bottom: 5%;
  z-index: 1;
  background-color: #260303;
  padding: 20px;
  border-radius: 50%;
}

.up-arrow i {
  color: white;
  font-size: 20px;
}

/* nav-bar */
/* General styles */

/* Home section */

#home {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap-reverse;
}

.home-div {
  flex: 50%;
  position: relative;
}

#home-div {
  padding: 15% 5% 0px 15%;
}

.home-div > h1 {
  font-family: "Paint Brush Script", sans-serif;
  font-size: 80px;
  color: #260303;
  margin-bottom: 10%;
}

.home-div > p {
  width: 80%;
  text-align: justify;
  margin-bottom: 20%;
}

.home-div > .order {
  text-decoration: none;
  color: #260303;
  background-color: #d9b4c1;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 600;
}

.home-div > .order:hover {
  background-color: #8c656f;
  color: #ebf0f2;
  border: 1px solid #d9b4c1;
}

.home-div > .back-img {
  position: absolute;
  width: 90%;
  top: 7%;
  right: 10%;
  transition: right 1s, top 1s;
}

.home-div:hover .back-img {
  position: absolute;
  right: 15%;
  top: 5%;
}

.home-div > .front-img {
  position: absolute;
  top: 10%;
  right: 2%;
  z-index: 1;
  width: 100%;
  transition: right 1s, top 1s;
}

.home-div:hover .front-img {
  right: 1%;
  top: 5%;
}

/* Catalogue section */

#Catalogue {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Catalogue-heading {
  width: 40%;
  padding: 0px 14%;
}

.catalogue-section > h1 {
  font-size: 80px;
}

.catalogue-section > h4 {
  color: #bfa450;
  font-size: 19px;
  margin-top: 2rem;
}

.Catalogue {
  width: 60%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.image-item {
  width: 100px;
  height: 300px;
  background-image: linear-gradient(135deg, #feada6, rgb(255 255 255 / 0%)),
    url("");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 1s;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  margin: 1rem;
}

.image-item:nth-child(2) {
  background-image: linear-gradient(135deg, #feada6, rgb(255 255 255 / 0%)),
    url("");
}

.image-item:nth-child(2):hover {
  background-image: none, url("");
}

.image-item:nth-child(3) {
  background-image: linear-gradient(135deg, #feada6, rgb(255 255 255 / 0%)),
    url("");
}

.image-item:nth-child(3):hover {
  background-image: none, url("");
}

.image-item:nth-child(4) {
  background-image: linear-gradient(135deg, #feada6, rgb(255 255 255 / 0%)),
    url("");
}

.image-item:nth-child(4):hover {
  background-image: none, url("");
}

.image-item:nth-child(5) {
  background-image: linear-gradient(135deg, #feada6, rgb(255 255 255 / 0%)),
    url("");
}

.image-item:nth-child(5):hover {
  background-image: none, url("");
}

.image-item a {
  opacity: 0;
  color: white;
  position: absolute;
  bottom: 2px;
  left: 50%;
  font-size: 2rem;
  transition: all 0.5s;
  font-family: "Paint Brush Script", sans-serif;
  transform: translate(-50%, -50%);
  text-decoration: none;
}

.image-item:hover {
  width: 300px;
  height: 300px;
  background-image: none, url("");
}

.image-item:hover a {
  position: absolute;
  bottom: 2px;
  left: 50%;
  opacity: 1;
  z-index: 2;
  transition: all 0.4s;
  transform: translate(-50%, -50%);
}

.image-item > a:hover {
  background: -webkit-linear-gradient(#eee 20%, #dc5f5f 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.image-item:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  border-radius: 20px;
  transition: all 0.4s;
  background-image: linear-gradient(0deg, #e65b00, rgb(255 255 255 / 0%));
}

/* cakes-section */

.product-section {
  width: 100%;
  overflow: hidden;
}

.cake-heading {
  width: 100%;
  padding: 2% 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cake-heading h1 {
  font-size: 50px;
  color: #260303;
}

.cake-heading h1 span {
  font-size: 20px;
  color: #c1a142;
}

.cake-heading a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #d9b4c1;
  background-color: #d9b4c1;
  color: #fff;
  transition: width 0.7s;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.cake-heading > a:hover {
  width: 180px;
  transition: width 0.7s;
}

.cake-heading > a > i {
  transition: opacity 0.5s;
}

.cake-heading a:hover i {
  opacity: 0;
}

.cake-heading > a > span {
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  width: 100%;
  left: 0;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cake-heading > a:hover span {
  opacity: 1;
}

.cake-slider {
  width: 100%;
  padding: 2% 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;
}

.slides-div {
  margin-top: 2%;
  position: relative;
  width: 180px;
  height: 250px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: rgba(255, 0, 0, 0.308) 0px 10px 20px;
}

.slides-div > * {
  grid-area: 1/1;
  transition: 0.4s;
}

.slides-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all 1s;
}

.slides-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(254, 173, 166, 0.39),
    rgba(255, 255, 255, 0.253)
  );
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 1;
}

.slides-div .img-details {
  position: absolute;
  bottom: 30%;
  left: 0;
  width: 100%;
  padding: 0.75rem;
  z-index: 2;
}

.slides-div a {
  display: block;
  font-family: "Paint Brush Script", sans-serif;
  font-size: 1.05rem;
  font-weight: bold;
  color: transparent;
  border-radius: 20px;
  text-align: center;
  padding: 0.75rem;
  transition: all 0.4s;
  opacity: 0;
  text-decoration: none;
}

.slides-div:hover img {
  box-shadow: 0 0 0 2px #8c656f51, 8px 8px 0 0 #8c656f51;
  transform: scale(1.3);
}

.slides-div:hover a {
  color: #260303;
  opacity: 1;
}

.slides-div a:hover {
  text-decoration: underline;
}

.slides-div:hover::before {
  opacity: 1;
}

/* break points : based on width
    480px - sm, 768px - md, 992px - lg, 1280px - xl
*/

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #fafafa;
}

::-webkit-scrollbar-thumb {
  background-color: #f2bbbb;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .icons {
    display: flex;
  }

  #close {
    display: none;
  }

  #check:checked ~ .icons #menu {
    display: none;
  }

  #check:checked ~ .icons #close {
    display: block;
  }

  nav {
    display: none;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    background-color: rgba(153, 147, 147, 0.092);
    backdrop-filter: blur(10px);
    width: 100%;
  }

  #check:checked + label + nav {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  nav > ul,
  nav > .cart-container {
    margin: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  nav a,
  ul li {
    margin: 0.5rem 0px;
  }

  /* catalogue */
  .catalogue-section {
    flex: 100%;
    text-align: center;
    padding-bottom: 5%;
  }

  .image-item {
    width: 75px;
    height: 300px;
  }

  /* footer */
  .footer-div {
    flex: 50%;
    margin-bottom: 5%;
  }
}

@media (max-width: 1100px) {
  /* home section */
  .home-div {
    flex: 100%;
    padding-bottom: 70%;
  }

  #home-div {
    z-index: 2;
  }
}

@media (max-width: 792px) {
  .Catalogue .image-item {
    width: 75px;
    height: 150px;
  }

  .image-item h2 {
    font-size: 2rem;
  }

  .image-item:hover {
    width: 150px;
    height: 150px;
  }

  .cake-heading span {
    display: block;
  }
}

@media (max-width: 600px) {
  /* catalogue */
  .image-item {
    width: 100px;
    height: 150px;
  }

  .image-item:hover {
    width: 150px;
    height: 150px;
  }

  /* product section */

  /* footer */
  footer {
    padding: 0;
    margin-top: 1rem;
  }
  .footer-div {
    flex: 100%;
    text-align: center;
    margin-bottom: 5%;
  }

  .icon-collections {
    padding: 0px 20%;
  }
}

@media (min-width: 100vw) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100vw;
    margin: 0 auto; /* Center the container */
    padding: 0;
    background: #fafafa;

  }
}

/* General styles */
.service-container {
  padding: 20px;
}

.service-section {
  margin-bottom: 30px;
}

.service-content {
  display: flex;
  flex-direction: row; /* Default layout for larger screens */
  align-items: center;
  margin-bottom: 20px;
}

.service-image {
  max-width: 100%;
  margin-right: 20px;
  border-radius: 8px;
  height: auto;
  max-height: 500px; /* Limit the height for larger screens */
  object-fit: cover; /* Ensures the image covers the container area */
}

.service-text {
  width: 60%;
}

/* Delivery charges */
.delivery-charges {
  margin-top: 2rem;
}

.delivery-charges h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.delivery-charges h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column; /* Stack image and text vertically on small screens */
    align-items: flex-start; /* Align items to the start */
  }

  .service-image {
    margin-right: 0;
    margin-bottom: 1rem; /* Add some space below the image */
  }

  .service-text {
    width: 100%; /* Full width on smaller screens */
    text-align: center; /* Center text on smaller screens */
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

table,
th,
td {
  border: 1px solid #000000;
}

th,
td {
  padding: 0.5rem;
  text-align: center;
}

tfoot tr {
  font-weight: bold;
}
.faq-page {
  font-family: "Paint Brush Script", sans-serif !important;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-header {
  text-align: center;
  margin-bottom: 30px;
}

.faq-divider {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #000000;
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;

  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.faq-answer {
  font-size: 1rem;
  margin: 0 0 15px 20px;
  padding: 10px;

  border-radius: 5px;
}

.icons-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.icon-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 10px;
  background-color: #bfe926;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: auto;
  }
}

/* Filters are always shown on medium and larger screens */
@media (max-width: 767px) {
  .filters-collapsible {
    display: none;
  }

  .filters-toggle-btn {
    display: block;
  }
}

/* Filters button on small screens */
.filters-toggle-btn {
  display: none;
}

.form-tabs {
  display: flex;
  justify-content: center;
}

.tab-btn {
  border: none;
  background-color: #f8f9fa;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.tab-btn.active {
  background-color: #f8a1f4;
  color: #fff;
}

.tab-btn:not(.active):hover {
  background-color: #e2e6ea;
}

/* Add these styles to your CSS file */
.btn-active {
  background-color: #f8a1f4; /* Primary color */
  color: white;
  border-color: #ffdcfa;
}

.btn-inactive {
  background-color: #000000; /* Light background */
  color: #6c757d; /* Light text color */
  border-color: #000000; /* Light border color */
}

.wrapper {
  padding-bottom: 2rem; /* Adjust this value as needed */
}

.hamburger-menu {
  position: fixed;
  top: 94px !important;
  left: 20px;
  z-index: 999;
  cursor: pointer;
}

.hamburger-icon {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 4px;
  background-color: #000;
  transition: 0.3s ease;
}

.hamburger-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.filter-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 290px;
  height: 100vh;
  background-color: #f8f9fa;
  transition: 0.3s ease;
  padding: 20px;
  z-index: 998;
}

.filter-sidebar.show {
  left: 0;
}

@media (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }

  .filter-sidebar {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    z-index: 1;
  }
}
.hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  cursor: pointer;
}

.hamburger-icon {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 4px;
  background-color: #000;
  transition: 0.3s ease;
}

.hamburger-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.filter-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 290px;
  height: 100vh;
  background-color: #f2c4daec;
  border: 1px solid black;
  transition: 0.3s ease;
  padding: 20px;
  z-index: 998;
}

.filter-sidebar.show {
  left: 0;
}

@media (min-width: 768px) {
  .hamburger-menu {
    display: none;
  }

  .filter-sidebar {
    position: static;
    width: 250px;
    height: auto;
    padding: 0;
    z-index: 1;
  }
}


/* cakeShop.css */
.cake-shop {
  min-height: 100vh;
  background-color: #fafafa;
}

/* Layout */
.cake-shop__layout {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

/* Products Grid */
.cake-shop__products {
  padding: 20px 0;
  width: 100%;
}

.cake-shop__products-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Single column for mobile */
  gap: 15px;
  padding: 0 10px;
}

/* Product Card Styles */
.cake-shop__product-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.cake-shop__product-card img {
  width: 100%;
  height: 200px; /* Fixed height for mobile */
  object-fit: cover;
}

.cake-shop__product-card-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cake-shop__product-title {
  font-size: 1rem;
  margin: 0;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cake-shop__product-price {
  font-weight: bold;
  color: #b7336a;
}

/* Hamburger Menu */
.cake-shop__hamburger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #b7336a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.cake-shop__hamburger-icon {
  width: 24px;
  height: 18px;
  position: relative;
}

.cake-shop__hamburger-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  transition: all 0.3s;
}

.cake-shop__hamburger-icon span:first-child {
  top: 0;
}

.cake-shop__hamburger-icon span:nth-child(2) {
  top: 8px;
}

.cake-shop__hamburger-icon span:last-child {
  top: 16px;
}

.cake-shop__hamburger-icon.open span:first-child {
  transform: rotate(45deg);
  top: 8px;
}

.cake-shop__hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.cake-shop__hamburger-icon.open span:last-child {
  transform: rotate(-45deg);
  top: 8px;
}

/* Filter Sidebar */
.cake-shop__filter-sidebar {
  background: white;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

/* Main Content */
.cake-shop__main-content {
  flex: 1;
  padding: 20px 0;
}

/* Heading */
.cake-shop__heading {
  font-size: 1.5rem;
  color: #666;
  margin: 20px 0;
  padding: 0 15px;
}

/* No Products Message */
.cake-shop__no-products {
  text-align: center;
  color: #666;
  padding: 20px;
  grid-column: 1 / -1;
}

/* Pagination Container */
.cake-shop__pagination {
  margin-top: 30px;
  padding: 0 15px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .cake-shop__filter-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 290px;
    z-index: 999;
    transform: translateX(-100%);
    overflow-y: auto;
  }

  .cake-shop__filter-sidebar.show {
    transform: translateX(0);
  }

  .cake-shop__main-content {
    width: 100%;
  }

  .cake-shop__product-card {
    max-width: 100%;
    margin: 0 auto;
  }
}



/* Small Mobile Styles */
@media (min-width: 480px) {
  .cake-shop__products-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablet Styles */
@media (min-width: 768px) {
  .cake-shop__products-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0;
  }

  .cake-shop__hamburger {
    display: none;
  }

  .cake-shop__filter-sidebar {
    width: 290px;
    margin-right: 20px;
    transform: none;
    position: static;
  }

  .cake-shop__product-card img {
    height: 180px;
  }
}

/* Desktop Styles */
@media (min-width: 1024px) {
  .cake-shop__products-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

/**/
.cake-shop__no-products {
  text-align: center;
  font-size: 1.2rem;
  margin: 20px auto;
}

.cake-shop__no-products-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media screen and (max-width: 430px) and (min-height: 932px) {
  .cake-shop__no-products {
    font-size: 1rem;
    margin: 15px auto;
  }

  .cake-shop__no-products-image {
    width: 80%;
    margin: 0 auto 10px;
  }
}
