/* Main footer container */
footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3% 5%;
    background-color: #fbc226a3;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

/* Footer section containers */
.footer-div {
    flex: 1 1 200px;
    margin: 10px;
}

/* Main footer section */
.main-footer-div {
    padding: 0 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

/* Logo styling */
#logo {
    max-width: 200px;
    height: 180px;
    padding: 10px;
    margin: 0 0 20px 0;
}

/* Social media icons container */
.icon-collections {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}

/* Individual social icons */
.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.icon:hover {
    background-color: #BFA450;
}

/* Brand icons color */
.brands {
    color: #260303;
}

.icon:hover .brands {
    color: white;
}

/* Contact link */
.contact-us {
    text-decoration: none;
    font-size: 1rem;
    margin-top: 1%;
}

/* Footer list items */
.footer-div li {
    list-style: none;
    margin-bottom: 5%;
    color: black;
    font-size: 18px;
}

/* Main headings in footer */
.footer-div .main {
    font-size: 22px;
    font-weight: 600;
    color: #260303;
}

/* Footer links */
.footer-div > li > a {
    text-decoration: none;
    color: black;
    font-size: 18px;
}

.footer-div > li > a:hover {
    color: #D9B4C1;
}

/* Icon spacing in footer links */
.footer-div > li > a > i {
    margin-right: 0.5rem;
}