/* Resetting margin and padding for a consistent look */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.order-invoice {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.order-invoice a {
  color: #5d6975;
  text-decoration: underline;
}

.order-invoice header {
  padding: 10px 0;
  margin-bottom: 30px;
}

#logo {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

#logo img {
  display: inline-block;
  width: 160px;
  height: auto;
}

.order-invoice h1 {
  border-top: 1px solid #5d6975;
  border-bottom: 1px solid #5d6975;
  color: #5d6975;
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
  background: url(../../../public/images/dimension.png);
  word-wrap: break-word; /* Breaks long text onto the next line */
}

#project,
#company {
  margin-bottom: 20px;
}

#project {
  float: left;
  width: 50%;
}

#company {
  float: right;
  width: 50%;
  text-align: right;
}

#project span,
#company span {
  color: #5d6975;
  font-size: 0.8em;
}

#project div,
#company div {
  margin-bottom: 10px;
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long text onto the next line */
}

.order-invoice table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-invoice table th,
.order-invoice table td {
  text-align: center;
  padding: 10px;
}

.order-invoice table th {
  color: #5d6975;
  border-bottom: 1px solid #000000;
  font-weight: normal;
}

.order-invoice table td {
  border-bottom: 1px solid #000000;
}

.order-invoice table td.service,
.order-invoice table td.desc {
  text-align: left;
}

.order-invoice table td.unit,
.order-invoice table td.qty,
.order-invoice table td.total {
  font-size: 1.2em;
}

.order-invoice table td.grand {
  border-top: 1px solid #5d6975;
}

#notices {
  margin-top: 20px;
}

#notices .notice {
  color: #5d6975;
  font-size: 1.2em;
}

.order-invoice footer {
  color: #5d6975;
  border-top: 1px solid #c1ced9;
  padding: 8px 0;
  text-align: center;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  #project,
  #company {
    float: none;
    width: 100%;
    text-align: left;
  }

  #company {
    text-align: left;
  }

  .order-invoice h1 {
    font-size: 1.8em;
  }

  #project span,
  #company span {
    font-size: 0.9em;
  }

  #project div,
  #company div {
    font-size: 1em;
  }

  .order-invoice table {
    font-size: 0.8em; /* Reduce font size for smaller screens */
    display: block; /* Make table a block-level element */
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  }

  .order-invoice table th,
  .order-invoice table td {
    padding: 8px; /* Adjust padding for smaller screens */
  }
}
