/* Base styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Arial", sans-serif;
}

/* VeggieDelights container */
.veggie-delights {
    background: linear-gradient(to bottom, #F2BBBB, #F2D5D5);
    padding: 2rem;
}

/* Container */
#container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}


/* Scroll to top button */
.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #e74c3c;
    padding: 1rem;
    border-radius: 50%;
    z-index: 1000;
}

.scroll-to-top a {
    color: #fff;
    font-size: 24px;
}

/* Home section */
#intro-section {
    text-align: center;
    padding: 4rem 0;
}

.intro-content h1 {
    font-size: 3rem;
    color: #333;
}

.intro-images {
    display: flex;
    justify-content: center;
}

.bg-image, .fg-image {
    max-width: 50%;
}

/* Product section */
.product-category {
    padding: 4rem 0;
}

.product-category h1 {
    font-size: 2.5rem;
    color: #333;
    text-align: center; /* Center align the header */
    margin-bottom: 2rem; /* Add spacing below the header */
}

.product-carousel {
    display: flex;
    justify-content: center; /* Center the product carousel */
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping if needed */
    gap: 1.5rem; /* Add spacing between cards */
}

.slides-div {
    position: relative;
    overflow: hidden;
    border-radius: 10px; /* Slightly rounded corners */
    box-shadow: 0 4px 8px rgba(231, 112, 185, 0.371); /* Default subtle glow */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transitions for hover effects */
    width: 169px; /* Fixed width for consistent spacing */
    height: 222px; /* Fixed height for consistent size */
    margin: 1rem; /* Margin around each card */
    background: #fff; /* White background to make the glow effect more visible */
}

.slides-div img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the card */
    border-radius: 10px; /* Match border radius with card */
}

.slides-div:hover {
    transform: translateY(-5px); /* Slightly lift effect on hover */
    box-shadow: 0 0 15px rgba(231, 112, 185, 0.371); /* Glowing effect */
}

.img-details {
    position: absolute;
    bottom: 0;
    background: rgba(234, 92, 135, 0.515);
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 0.5rem;
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.4s ease-in-out;
}

.slides-div:hover .img-details {
    opacity: 1;
    transform: translateY(0);
}

/* Hero Section */
.hero-section {
    display: flex;
    flex-direction: column; /* Stack vertically on small screens */
    align-items: center;
    padding: 20px;
    background: linear-gradient(to bottom, #F2BBBB);
    gap: 20px;
}

.hero-box {
    width: 100%;
    padding: 10px;
    text-align: center; /* Center text on small screens */
}

.hero-text {
    max-width: 100%;
    margin-bottom: 20px; /* Space between text and button on small screens */
}

.hero-text h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
    color: #000000;
}

.hero-text p {
    margin: 10px 0;
    font-size: 1rem; /* Adjust font size for smaller screens */
    color: #666;
}

.hero-button {
    padding: 8px 16px;
    font-size: 1rem;
    color: #fff;
    background-color: #e0445983;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hero-button:hover {
    background-color: #ff069b2e;
}

.hero-images {
    display: grid; /* Switch to grid layout for small screens */
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    max-width: 100%;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* One column on small screens */
    grid-template-rows: auto;
    gap: 20px;
}

.hero-image {
    object-fit: cover;
    border-radius: 10px;
    opacity: 0; /* Start invisible */
    transform: translateY(50px); /* Start off-screen to the bottom */
    animation: slide-in 1s forwards; /* Trigger the slide-in animation */
}

.hero-image:nth-child(1) {
    grid-column: span 1; /* One column width */
    height: 200px; /* Height of the first image */
    animation-delay: 0.2s;
}

.hero-image:nth-child(2) {
    grid-column: span 1; /* One column width */
    height: 300px; /* Height of the second image */
    animation-delay: 0.4s;
}

.hero-image:nth-child(3) {
    grid-column: span 1; /* One column width */
    height: 150px; /* Height of the third image */
    animation-delay: 0.6s;
}

.hero-image:nth-child(4) {
    grid-column: span 1; /* One column width */
    height: 250px; /* Height of the fourth image */
    animation-delay: 0.8s;
}

@keyframes slide-in {
    to {
        opacity: 1; /* Fade in */
        transform: translateY(0); /* Slide into position */
    }
}

/* Media Queries */
@media (min-width: 768px) {
    .hero-section {
        flex-direction: row; /* Arrange items in a row on medium and larger screens */
        justify-content: space-between;
    }

    .hero-box {
        width: 50%;
        padding: 20px;
    }

    .hero-text {
        max-width: 45%;
    }

    .hero-images {
        max-width: 45%;
        grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
    }

    .image-grid {
        grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
    }

    .hero-image {
        transform: translateX(-50px); /* Slide in from the left on medium screens */
    }
}

@media (min-width: 992px) {
    .hero-text h1 {
        font-size: 3rem; /* Larger font size on large screens */
    }

    .hero-text p {
        font-size: 1.5rem; /* Larger font size on large screens */
    }

    .hero-button {
        font-size: 1.2rem;
    }
}
